export type supportedModel =
    | 'toy'
    | 'collectionMeta'
    | 'banner'
    | 'bannerMobile'
    | 'artistsAvatar'
    | 'artistSignature'
    | 'artistGalleryItem'
    | 'tweetsAvatar'
    | 'promoBackground'
    | 'promoForeground'
    | 'feedBannerFront'
    | 'feedBannerBack'
    | 'goldPackages'
    | 'collectionPack'
    | 'collectionPackDropBG'
    | 'partnerDropMainBannerSmall'
    | 'partnerDropPopup_desktop'
    | 'partnerDropPopup_mobile'
    | 'partnerDropCardBackCover'
    | 'partnerDropLogo'
    | 'partnerDropSecondLogo'
    | 'partnerDropPageBackground_desktop'
    | 'partnerDropPageBackground_mobile'
    | 'collectionMetaDraft'
    | 'news'
    | 'posts'

export type dimensions = {
    width: number
    height: number
}
export type sizesModel = Array<
    dimensions & {
        remotePath: string
        isMobile: boolean
        mode?: 'cover' | 'contain'
        sourceExtension?: 'jpg' | 'png'
    }
>

const basePath = '/RToys'

export enum CdnPath {
    news = `${basePath}/news`,
    posts = `${basePath}/posts`,
    footer_decore = `${basePath}/footer_decore`,
    products = `${basePath}/products`,
    collectionMeta = `/collection-meta`,
    exploreNav = `${basePath}/explore-nav`,
}

type SizeTemplate = Record<supportedModel, sizesModel>
export const sizes: SizeTemplate = {
    posts: [
        {
            width: 400,
            height: 225,
            isMobile: true,
            remotePath: CdnPath.posts,
        },
        {
            width: 820,
            height: 480,
            isMobile: false,
            remotePath: CdnPath.posts,
        },
    ],
    toy: [
        {
            width: 500,
            height: 500,
            isMobile: false,
            remotePath: '/toys/main',
        },
        {
            width: 375,
            height: 375,
            isMobile: true,
            remotePath: '/toys/main',
        },
        {
            width: 360,
            height: 360,
            isMobile: false,
            remotePath: '/toys/header',
        },
        {
            width: 278,
            height: 278,
            isMobile: true,
            remotePath: '/toys/header',
        },
        {
            width: 180,
            height: 180,
            isMobile: false,
            remotePath: '/toys/cards',
        },
        {
            width: 140,
            height: 140,
            isMobile: true,
            remotePath: '/toys/cards',
        },
        {
            width: 220,
            height: 220,
            isMobile: false,
            remotePath: '/toys/artist-cards',
        },
        {
            width: 140,
            height: 140,
            isMobile: true,
            remotePath: '/toys/artist-cards',
        },
        {
            width: 57,
            height: 57,
            isMobile: false,
            remotePath: '/toys/favicons/57',
        },
        {
            width: 60,
            height: 60,
            isMobile: false,
            remotePath: '/toys/favicons/60',
        },
        {
            width: 72,
            height: 72,
            isMobile: false,
            remotePath: '/toys/favicons/72',
        },
        {
            width: 76,
            height: 76,
            isMobile: false,
            remotePath: '/toys/favicons/76',
        },
        {
            width: 114,
            height: 114,
            isMobile: false,
            remotePath: '/toys/favicons/114',
        },
        {
            width: 120,
            height: 120,
            isMobile: false,
            remotePath: '/toys/favicons/120',
        },
        {
            width: 144,
            height: 144,
            isMobile: false,
            remotePath: '/toys/favicons/144',
        },
        {
            width: 152,
            height: 152,
            isMobile: false,
            remotePath: '/toys/favicons/152',
        },
        {
            width: 180,
            height: 180,
            isMobile: false,
            remotePath: '/toys/favicons/180',
        },
        {
            width: 196,
            height: 196,
            isMobile: false,
            remotePath: '/toys/favicons/180',
        },
        {
            width: 512,
            height: 512,
            isMobile: false,
            remotePath: '/toys/favicons/180',
        },
    ],
    collectionMeta: [
        {
            width: 632,
            height: 758,
            isMobile: false,
            remotePath: '/collection-meta',
        },
        {
            width: 335,
            height: 402,
            isMobile: true,
            remotePath: '/collection-meta',
        },
    ],
    collectionMetaDraft: [
        {
            width: 632,
            height: 758,
            isMobile: false,
            remotePath: '/collection-meta-draft',
        },
        {
            width: 335,
            height: 402,
            isMobile: true,
            remotePath: '/collection-meta-draft',
        },
    ],
    promoBackground: [
        {
            width: 400,
            height: 400,
            isMobile: false,
            remotePath: '/promo/background',
        },
        {
            width: 1200,
            height: 230,
            isMobile: false,
            remotePath: '/promo/background/feed',
        },
        {
            width: 360,
            height: 170,
            isMobile: true,
            remotePath: '/promo/background/feed',
        },
    ],
    news: [
        {
            width: 336,
            height: 220,
            isMobile: false,
            remotePath: CdnPath.news,
        },
        {
            width: 220,
            height: 142,
            isMobile: true,
            remotePath: CdnPath.news,
        },
    ],
    promoForeground: [
        {
            width: 238,
            height: 238,
            isMobile: false,
            remotePath: '/promo/foreground',
        },
        {
            width: 105,
            height: 105,
            isMobile: true,
            remotePath: '/promo/foreground',
        },
        {
            width: 550,
            height: 180,
            isMobile: false,
            remotePath: '/promo/foreground/feed',
        },
        {
            width: 230,
            height: 70,
            isMobile: true,
            remotePath: '/promo/foreground/feed',
        },
    ],
    banner: [
        {
            width: 1440,
            height: 60,
            isMobile: false,
            remotePath: '/banner',
        },
    ],
    bannerMobile: [
        {
            width: 375,
            height: 60,
            isMobile: true,
            remotePath: '/banner',
        },
    ],
    tweetsAvatar: [
        {
            width: 40,
            height: 40,
            isMobile: false,
            remotePath: '/tweets/avatars',
            mode: 'cover',
            sourceExtension: 'jpg',
        },
        { width: 40, height: 40, isMobile: true, remotePath: '/tweets/avatars', mode: 'cover', sourceExtension: 'jpg' },
    ],
    artistsAvatar: [
        {
            width: 375,
            height: 375,
            isMobile: false,
            remotePath: '/artists/avatars',
            mode: 'cover',
            sourceExtension: 'jpg',
        },
        {
            width: 335,
            height: 335,
            isMobile: true,
            remotePath: '/artists/avatars',
            mode: 'cover',
            sourceExtension: 'jpg',
        },
        {
            width: 180,
            height: 180,
            isMobile: false,
            remotePath: '/artists/avatars/card',
            mode: 'cover',
            sourceExtension: 'jpg',
        },
        {
            width: 140,
            height: 140,
            isMobile: true,
            remotePath: '/artists/avatars/card',
            mode: 'cover',
            sourceExtension: 'jpg',
        },
        {
            width: 80,
            height: 80,
            isMobile: false,
            remotePath: '/artists/avatars/info',
            mode: 'cover',
            sourceExtension: 'jpg',
        },
        {
            width: 80,
            height: 80,
            isMobile: true,
            remotePath: '/artists/avatars/info',
            mode: 'cover',
            sourceExtension: 'jpg',
        },
    ],
    artistSignature: [
        {
            width: 200,
            height: 200,
            isMobile: false,
            remotePath: '/artists/avatars',
            mode: 'contain',
            sourceExtension: 'png',
        },
        {
            width: 48,
            height: 35,
            isMobile: true,
            remotePath: '/artists/avatars',
            mode: 'contain',
            sourceExtension: 'png',
        },
        {
            width: 113,
            height: 80,
            isMobile: false,
            remotePath: '/artists/avatars/on_page',
            mode: 'contain',
            sourceExtension: 'png',
        },
        {
            width: 80,
            height: 56,
            isMobile: true,
            remotePath: '/artists/avatars/on_page',
            mode: 'contain',
            sourceExtension: 'png',
        },
        {
            width: 80,
            height: 56,
            isMobile: false,
            remotePath: '/artists/avatars/info',
            mode: 'contain',
            sourceExtension: 'png',
        },
        {
            width: 80,
            height: 56,
            isMobile: true,
            remotePath: '/artists/avatars/info',
            mode: 'contain',
            sourceExtension: 'png',
        },
    ],
    artistGalleryItem: [
        {
            width: 1200,
            height: 1200,
            isMobile: false,
            remotePath: '/artists/attachments/catalog/open',
            sourceExtension: 'jpg',
        },
        {
            width: 800,
            height: 800,
            isMobile: true,
            remotePath: '/artists/attachments/catalog/open',
            sourceExtension: 'jpg',
        },
        {
            width: 286,
            height: 286,
            isMobile: false,
            remotePath: '/artists/attachments/catalog/page',
            sourceExtension: 'jpg',
        },
        {
            width: 157,
            height: 157,
            isMobile: true,
            remotePath: '/artists/attachments/catalog/page',
            sourceExtension: 'jpg',
        },
        {
            width: 157,
            height: 157,
            isMobile: false,
            remotePath: '/artists/attachments/catalog/toy',
            sourceExtension: 'jpg',
        },
        {
            width: 157,
            height: 157,
            isMobile: true,
            remotePath: '/artists/attachments/catalog/toy',
            sourceExtension: 'jpg',
        },
    ],
    feedBannerFront: [
        {
            width: 270,
            height: 270,
            isMobile: false,
            remotePath: '/feed/banner/foreground',
        },
        {
            width: 270,
            height: 270,
            isMobile: true,
            remotePath: '/feed/banner/foreground',
        },
    ],
    feedBannerBack: [
        {
            width: 500,
            height: 500,
            isMobile: false,
            remotePath: '/feed/banner/background',
        },
        {
            width: 500,
            height: 500,
            isMobile: true,
            remotePath: '/feed/banner/background',
        },
    ],
    goldPackages: [
        {
            width: 204,
            height: 171,
            isMobile: false,
            remotePath: CdnPath.products,
        },
        {
            width: 149,
            height: 125,
            isMobile: true,
            remotePath: CdnPath.products,
        },
    ],
    collectionPack: [
        {
            width: 412,
            height: 478,
            isMobile: false,
            remotePath: '/collection/packs',
        },
        {
            width: 190,
            height: 220,
            isMobile: true,
            remotePath: '/collection/packs',
        },
    ],
    collectionPackDropBG: [
        {
            width: 1176,
            height: 516,
            isMobile: false,
            remotePath: '/collection/packs/drops',
        },
        {
            width: 769,
            height: 367,
            isMobile: true,
            remotePath: '/collection/packs/drops',
        },
    ],
    partnerDropMainBannerSmall: [
        {
            width: 365,
            height: 174,
            isMobile: true,
            remotePath: '/partners/partnerDropMainBannerSmall',
        },
    ],
    partnerDropPopup_desktop: [
        {
            width: 794,
            height: 466,
            isMobile: false,
            remotePath: '/partners/partnerDropPopup_desktop',
        },
    ],
    partnerDropPopup_mobile: [
        {
            width: 365,
            height: 608,
            isMobile: true,
            remotePath: '/partners/partnerDropPopup_mobile',
        },
    ],
    partnerDropCardBackCover: [
        {
            width: 270,
            height: 305,
            isMobile: false,
            remotePath: '/partners/partnerDropCardBackCover',
        },
        {
            width: 179,
            height: 265,
            isMobile: true,
            remotePath: '/partners/partnerDropCardBackCover',
        },
    ],
    partnerDropLogo: [
        {
            width: 211,
            height: 137,
            isMobile: false,
            remotePath: '/partners/partnerDropLogo',
        },
        {
            width: 100,
            height: 65,
            isMobile: true,
            remotePath: '/partners/partnerDropLogo',
        },
    ],
    partnerDropSecondLogo: [
        {
            width: 232,
            height: 63,
            isMobile: false,
            remotePath: '/partners/partnerDropSecondLogo',
        },
    ],
    partnerDropPageBackground_desktop: [
        {
            width: 1920,
            height: 879,
            isMobile: false,
            remotePath: '/partners/partnerDropPageBackground_desktop',
        },
    ],
    partnerDropPageBackground_mobile: [
        {
            width: 375,
            height: 879,
            isMobile: true,
            remotePath: '/partners/partnerDropPageBackground_mobile',
        },
    ],
}
