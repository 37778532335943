export const TRUNCATE_PREVIEW_MAX_CHARS_DESKTOP = 200

export const TRUNCATE_PREVIEW_MAX_CHARS_MOBILE = 100
export const MAX_POST_PICTURE_FILE_SIZE = 5 * 1024 * 1024 // 5Mb

export const LOCAL_LOADED_IMAGE = 'data:image' as const
export const LOCAL_LOADED_URL_IMAGE = 'blob:' as const
export const MAX_POST_CHARS_LIMIT = 10_000
export const MAX_POST_HEADING_CHARS_LIMIT = 60
export const MAX_POST_PICTURES = 10
