import {
    artist,
    badge,
    contract,
    digitalDrop,
    nft,
    OffersBannerTypes,
    ownership,
    Prisma,
    profile,
    provider,
    rarity,
    ReviewResult,
    ReviewStatus,
    stats,
    storeItem,
    SubscriptionPlan,
    toy,
    ValueTypes,
    wallet,
} from '@prisma/client'

import { Session } from 'next-auth'

export {
    achivement,
    award,
    badge,
    DropMechanics,
    feed_event,
    feed_source,
    OffersBannerTypes,
    OperationTypes,
    PaymentService,
    payoutStatus,
    period,
    ProfileLevel,
    ProfileType,
    provider,
    PublicationType,
    PurchaseTypes,
    questVisibility,
    rackTheme,
    ReviewStatus,
    SubscriptionPlan,
    ToyDraftStatus,
    TransactionStatus,
    ValueTypes,
} from '@prisma/client'
export type {
    feedTrigger,
    account,
    adsVideos,
    artist,
    artistAttachment,
    artistDraft,
    avatar,
    ban,
    banner,
    blindBox,
    blindBoxToys,
    burnedOwnership,
    charge,
    collectedToysCache,
    collectionBonus,
    collectionMeta,
    competition,
    competitionCode,
    coupon,
    couponUsage,
    currencyRatesToUSD,
    customBanner,
    drop,
    dropEarning,
    dropOwnerships,
    feed,
    inCollection,
    invoice,
    invoiceProduct,
    inWishlist,
    journal,
    kycData,
    leaderboard,
    like,
    meta,
    metamaskWallet,
    mintSetup,
    model3d,
    nft,
    offer,
    openEdition,
    ownership,
    pack,
    partner,
    partnerDropContent,
    partnerLine,
    partnerRarityLineRequirement,
    payment,
    payout,
    product,
    profile,
    profileAlias,
    profileAward,
    promo,
    promoCode,
    purchase,
    quest,
    questMechanic,
    questReward,
    rack,
    rarity,
    // script,
    secretCode,
    secretCodeInGroup,
    session,
    shippingRequest,
    social,
    socialToyShare,
    stats,
    storeItem,
    stripeCoupon,
    subscription,
    toy,
    toyDraft,
    transfer,
    tweet,
    uploadIntent,
    user,
    wallet,
    collectionMetaDraft,
    inCollectionDraft,
    collectionBonusDraft,
    contract,
    AlternativeCollects,
} from '@prisma/client'
export type { ChikoApiError, ChikoApiResponce } from './deprecated/apiResponce'
export { ApiRoutes } from './deprecated/apiRoutes'
export type ChikoSession =
    | (Session & {
          uid: number
      })
    | undefined
/**
 * Стандартный формат для ошибок бизнес логики.
 * */
export type ChikoReject<codes extends ERRORS_TYPES, data = unknown> = {
    code: codes
    status: 'reject'
    data?: data
}

export type MediaItem = {
    translateKey: string
    href: string
    icon: string
}
export type kycDataUncheckedCreateInput = {
    profileId: number
    isIdentityVerified?: boolean
    status?: ReviewStatus
    result?: ReviewResult | null
    applicantId: string
}

export type ERRORS_TYPES =
    | 'error.default'
    | 'error.metamask'
    | 'main.drops.noToy'
    | 'main.drops.notFound'
    | 'main.drops.notAvailable'
    | 'main.drops.alreadyOwned'
    | 'main.drops.artClub'
    | 'main.drops.region.restricted'
    | 'main.drops.wrongSecret'
    | 'main.blindbox.limit.exhausted'
    | 'main.blindbox.balance.notenough'
    | 'main.blindbox.toys.empty'
    | 'main.blindbox.color.dublicate'
    | 'main.profiles.shortId.conflict'
    | 'main.profiles.shortId.length.min'
    | 'main.profiles.shortId.length.max'
    | 'main.profiles.shortId.filter'
    | 'main.profiles.shortId.limit'
    | 'main.rack.not.owner'
    | 'main.rack.free.limit.exhausted'
    | 'main.rack.balance.notenough'
    | 'main.rack.profile.not.found'
    | 'main.rack.variation.not.found'
    | 'main.bonusToy.alreadyOwned'
    | 'main.bonusToy.notBonus'
    | 'main.bonusToy.notAvailable'
    | 'main.bonusToy.notAllCollected'
    | 'main.bonusToy.notfound'
    | 'main.leaderboard.stats.notFound'
    | 'main.profiles.notFound'
    | 'burn.not.owner'
    | 'burn.toy.notfound'
    | 'burn.toy.one.restricted'
    | 'burn.config.rarity.notfound'
    | 'burn.config.rate.notfound'
    | 'burn.toy.supplied.null'
    | 'burn.toy.limit.exhausted'
    | 'burn.toy.store.exists'
    | 'burn.toy.rack.member'
    | 'main.unauthurised'
    | 'main.upload.no.nontent-type'
    | 'main.upload.not.image'
    | 'main.upload.not.supported'
    | 'main.rack.worng.price'
    | 'cloud.intent.error'
    | 'cloud.intent.not.found'
    | 'cloud.intent.draft'
    | 'main.store.purchase.price.mismatch'
    | 'main.store.purchase.item.not.found'
    | 'main.store.purchase.balance.red'
    | 'main.kyc.required'
    | 'main.store.notFound'
    | 'main.store.price.negative'
    | 'main.store.price.fractional'
    | 'main.store.ownership.not.found'
    | 'main.store.dublicate'
    | 'main.store.toy.restricted'
    | 'system.ban.dublicate'
    | 'system.profile.notfound'
    | 'system.transaction.error'
    | 'store.item.not.available'
    | 'store.item.ended'
    | 'cart.is.full'
    | 'main.competitions.no.available.'
    | 'main.competitions.not.completed'
    | 'main.competitions.already.completed'
    | 'main.competitions.wrong.mechanics'
    | 'main.competitions.service.error'
    | 'payments.product.not.found'
    | 'payments.subscription.not.found'
    | 'payments.subscription.isPendingFinish'
    | 'payments.provider.not.supported'
    | 'payments.stripe.product.delete.error'
    | 'payments.stripe.product.not.found'
    | 'payments.stripe.price.not.found'
    | 'main.drops.retro.timer'
    | 'main.balance.red'
    | 'drops.retro.skipped.dublicate'
    | 'main.subscription.required'
    | 'promocode.generate.error'
    | 'promocode.transaction.error'
    | 'promocode.cannot.find.active'
    | 'system.idempotency.error'
    | 'main.ads.notWatched'
    | 'main.ads.wrong.mechanics'
    | 'main.draft.donotFind'
    | 'main.draft.trx.error'
    | 'personal.account.myToy.upload.model.error'
    | 'personal.account.myToy.upload.model.need.to.upload'
    | 'personal.account.myToy.upload.model.need.to.allFileds'
    | 'payout.balance.limit.exhausted'
    | 'gold.give.error'
    | 'swapUserEmails.old.user.not.found'
    | 'swapUserEmails.new.user.not.found'
    | 'swapUserEmails.cannot.swap.regular.users'
    | 'swapUserEmails.cannot.swap.accounts.users'
    | 'swapUserEmails.cannot.swap.regular.account.users'
    | 'swapUserEmails.cannot.swap.account.regular.users'
    | 'swapUserEmails.first.package.error'
    | 'swapUserEmails.second.package.error'
    | 'swapUserEmails.profiles.not.found'
    | 'kyc.always.green'
    | 'swapUserEmails.profile.artists.swap'
    | 'swapUserEmails.profile.avatar.swap'
    | 'share.not.valide.code'
    | 'share.not.exist'
    | 'artist.not.exist'
    | 'toy.not.exist'
    | 'cannot.create.share.post'
    | 'share.cannot.transfer.100.gold'
    | 'share.cannot.transfer.25.gold'
    | 'share.cannot.transfer.wright.reward'
    | 'share.cannot.found.any.posts'
    | 'share.cannot.apply.ur.own.code'
    | 'openEdition.toy.is.used.in.drop'
    | 'openEdition.is.not.found'
    | 'rarity.not.found'
    | 'openEdition.incorrect.time.range'
    | 'elastic.update.index.error'
    | 'elastic.update.index.success'
    | 'collection.pack.save.rates.error'
    | 'collection.pack.save.rates.success'
    | 'partner.line.collection.dont.match'
    | 'partner.dont.find'
    | 'partner.line.length.dont.metch'
    | 'partner.pack.delay'
    | 'main.partners.line.notcollected'
    | 'dateNotAllowed'
    | 'convert.usd.error'
    | 'convert.currency.do.not.found'
    | 'partner.line.lineMechanics.dont.match'
    | 'shippingRequest.create.error'
    | 'partner.line.mechanic.dont.exist'
    | 'partner.line.rarites.dont.match'
    | 'offers.price.min.gold'
    | 'offers.price.min.ruby'
    | 'offers.instanceid.not.found'
    | 'offers.offer.not.found'
    | 'offers.is.not.owner'
    | 'offers.ownership.not.found'
    | 'ownership.not.owner'
    | 'ownership.already.minted'
    | 'system.wallet.not.connected'
    | 'wallet.connected.to.another.account'
    | 'system.contract.error'
    | 'store.nft.not.owner'
    | 'ownership.mint.partner.required'
    | 'toy.mint.disabled'
    | 'offersBanner.not.found'
    | 'main.coupon.notFound'
    | 'main.invoice.notFound'
    | 'quest.not.completed'
    | 'quest.already.completed'
    | 'quest.finished'
    | 'quest.profile.already.have.subscription'
    | 'blindbox.is.not.exist'
    | 'blindbox.totalProbability.is.not.correct'
    | 'blindbox.collect.noToy'
    | 'blindbox.collect.notAvailable'
    | 'blindbox.collect.trx.error'
    | 'not.optimal.ruby.set'
    | 'main.form.fields.required'
    | 'main.not.qr.exists'
    | 'coupon.noVerify.requires.subscription'
    | 'coupon.validFrom.not.come'
    | 'coupon.validUntil.end'
    | 'coupon.alredy.used'
    | 'coupon.purchaseLimit.miss'
    | 'coupon.minimumSpendThreshold.miss'
    | 'coupon.currencyType.dont.match'
    | 'coupon.not.reusable'
    | 'personal.account.myToy.toy.name.min'
    | 'personal.account.myToy.toy.name.max'
    | 'toy.mint.no.contract'
    | 'main.social.delete.error'
    | 'main.store.price.too.high'
    | 'post.not.author'
    | 'upload.image.413'
    | 'create.toy.invalid.form'
    | 'create.toy.toy.name.min.error'
    | 'create.toy.toy.name.max.error'
    | 'create.toy.upload.image.error'
    | 'create.toy.price.error'
    | 'create.toy.circulation.error'
    | 'crop.image.error'
    | 'no.file.provided'
    | 'upload.failed'
    | 'please.check.your.file'
    | 'generate.qr.codes.max.length'

export const RarityTypeRoutes = ['common', 'rare', 'super', 'epic', 'legendary', 'mythical', 'one'] as const
export const SortOrderRoutes = ['a-z', 'z-a', 'popular', 'unpopular', 'newest', 'oldest', 'cheapest', 'expensive', 'freshest', 'stale'] as const
export const RegionFilters = [undefined, 'RU', 'US', 'ID', 'IN', 'CN'] as const
export const ToysFilters = ['collected', 'remaining', 'wishlist'] as const
export const OfferFilters = ['my', 'withoutMy'] as const
export const AvailableBadges = ['artClub', 'gift', 'animated', 'digitalTwin', 'blindbox'] satisfies badge[]
export const FeedFilters = ['Artoys', 'Personal', 'Following', 'Artists'] as const
export const SortStoreItemsRoutes = ['chepest', 'expensive', 'new', 'old', 'instanceIdAsc', 'instanceIdDesc'] as const
export const UserLevelsTypes = ['Common', 'Rare', 'SuperRare', 'Epic', 'Legendary', 'Mythical', 'OneAndOnly'] satisfies stats['level'][]
export const CoolectionSortOrderRoutes = ['a-z', 'z-a', 'popular', 'unpopular', 'newest', 'oldest', 'freshest', 'stale'] as const
export const SubscriptionPlans = ['year', 'month', 'sixMonths'] satisfies SubscriptionPlan[]
export const SocialProviders = ['discord', 'vk', 'facebook', 'telegram', 'tiktok', 'google'] satisfies provider[]
export const PersonalQrCodeFilters = ['single', 'multiple'] as const
export const PersonalDropsFilters = [undefined, 'IN_MODERATION', 'EXPORTED', 'NEW'] as const
export const PaymentProductsTypes = ['subscription', 'gold', 'promoCodes', 'ruby'] as const
export const PaymentValueTypes = ['GOLD', 'MONEY', 'RUBY', 'TOY'] satisfies ValueTypes[]
export const OffersBannerValueTypes = ['empty', 'PRODUCT', 'SUBSCRIPTION', 'TOY', 'COLLECTION'] satisfies OffersBannerTypes[]
export const ArtistProfileStatisticRange = ['day', 'week', 'month'] as const
export const ArtistProfileStatisticView = ['subscribers', 'collectors', 'gold'] as const
export const ShopifyStatuses = ['ACTIVE', 'ARCHIVED', 'DRAFT'] as const
export const ShopifyPublishedStatuses = ['published', 'unpublished', 'any'] as const

export type RarityTypeRoutes = (typeof RarityTypeRoutes)[number]
export type SortOrderRoutes = (typeof SortOrderRoutes)[number]
export type OfferFilters = (typeof OfferFilters)[number]
export type RegionFilters = (typeof RegionFilters)[number]
export type ToysFilters = (typeof ToysFilters)[number]
export type AvailableBadges = (typeof AvailableBadges)[number]
export type FeedFilters = (typeof FeedFilters)[number]
export type SortStoreItemsRoutes = (typeof SortStoreItemsRoutes)[number]
export type UserLevelsTypes = (typeof UserLevelsTypes)[number]
export type CoolectionSortOrderRoutes = (typeof CoolectionSortOrderRoutes)[number]
export type SubscriptionPlans = (typeof SubscriptionPlans)[number]
export type SocialProviders = (typeof SocialProviders)[number]
export type PersonalDropsFilters = (typeof PersonalDropsFilters)[number]
export type PersonalQrCodeFilters = (typeof PersonalQrCodeFilters)[number]
export type PaymentProductsTypes = (typeof PaymentProductsTypes)[number]
export type PaymentValueTypes = (typeof PaymentValueTypes)[number]
export type OffersBannerValueTypes = (typeof OffersBannerValueTypes)[number]
export type ArtistProfileStatisticRange = (typeof ArtistProfileStatisticRange)[number]
export type ArtistProfileStatisticView = (typeof ArtistProfileStatisticView)[number]
export type ShopifyStatusesType = (typeof ShopifyStatuses)[number]
export type ShopifyPublishedStatusesType = (typeof ShopifyPublishedStatuses)[number]
export type feedEventContructor = Prisma.feedCreateInput
export type feedEventUpsert = Prisma.feedUpsertArgs
export type PriceRange =
    | {
          min: number
          max: number
          currency?: ValueTypes
      }
    | {
          min: number
          currency?: ValueTypes
      }
    | {
          max: number
          currency?: ValueTypes
      }
/** Mapping для соответствия роута в uri обьекту rarity в бд. */
export const RarityDatabaseID = {
    common: 2,
    rare: 3,
    super: 4,
    epic: 5,
    legendary: 6,
    mythical: 7,
    one: 8,
} as const satisfies Record<RarityTypeRoutes, rarity['id']>
export type Concat<S1 extends string, S2 extends string> = `${S1}${S2}`
export const getCollectionSort = (sort: SortOrderRoutes | undefined): Exclude<SortOrderRoutes, 'cheapest' | 'expensive'> | undefined => (sort === 'cheapest' || sort === 'expensive' ? 'newest' : sort)

export const RarityBackroundsDictionary = {
    2: '#F5B452',
    3: '#91DA65',
    4: '#44B9DE',
    5: '#E94E4E',
    6: '#A162F0',
    7: '#71D6C3',
    8: '#E7EA79',
} as const

export namespace filters {
    export const MIN_PRICE = 50
}
export type InfiniteListType<T> = {
    items: Array<T>
    nextCursor: number | undefined
}
export type ListType<T> = {
    items: Array<T>
    nextCursor: number | undefined
    totalPages: number
}

type HttpMethod = 'GET' | 'POST'
export type SumsubAPIConfig = {
    method: HttpMethod
    url: string
    body?: string | Buffer
    headers: HeadersInit
}
export const exsitingLangs = ['en', 'ru', 'zh', 'ja', 'pt']
export type OriginalStoreItem = storeItem & {
    ownershipId: number
    instanceid: number
    digitalDropId: null
    digitalDrop: null
    ownership: NonNullable<
        ownership & {
            nft: nft & { contract: contract }
            profile: profile & {
                wallet: wallet
            }
        }
    >
    toy: toy & {
        artist: artist
        collaborators: Array<{ id: artist['id']; profile: { id: profile['id']; shortid: profile['shortid'] } | null }>
    }
}
export type DigitalDropStoreItem = storeItem & {
    ownershipId: null
    instanceid: null
    ownership: null
    digitalDropId: number
    digitalDrop: digitalDrop & {
        artist: artist & {
            profile: profile
        }
    }
    toy: toy & {
        artist: artist
        collaborators: Array<{ id: artist['id']; profile: { id: profile['id'] } | null }>
    }
}

export type CartCheckoutRejectBody = Array<{ storeItemId: storeItem['id']; price: storeItem['price']; status: 'available' | 'store.item.not.available' | 'store.item.ended' }>
export type CartInputBody = Array<{
    toyId: toy['id']
    storeItemId: storeItem['id']
    price: storeItem['price']
    currency: storeItem['currency']
}>
