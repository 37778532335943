import { IBurnVariant, ICountry } from '@helpers/types'
import { Dictionary } from '@hooks/useTranslation'
import { profile } from '@prisma/client'
import { model3d, RarityTypeRoutes } from '@specs/specs'
import { IPrivacyItem } from '@specs/types'
import { createHash, createHmac } from 'crypto'
import { LinksEnum } from '@specs/navigation'
import { modelExtensionTypes } from '@services/toys/helpers'

const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
const SOCIAL_REGEXP = /^@.+$/i

export const isEmailValid = (value: string) => EMAIL_REGEXP.test(value)
export const isSocialValid = (value: string) => SOCIAL_REGEXP.test(value)

export type getRedirect = (
    condition: Promise<boolean>,
    destination?: string,
    permanent?: boolean,
) => Promise<Record<
    'redirect',
    {
        permanent: boolean
        destination: string
    }
> | void>
export type getAdminRedirect = (
    profilePromise: Promise<profile | undefined>,
    destination?: string,
    permanent?: boolean,
) => Promise<Record<
    'redirect',
    {
        permanent: boolean
        destination: string
    }
> | void>

export const profileCheck: getRedirect = async (condition, destination = LinksEnum.SIGN_IN, permanent = false) => {
    if (await condition) {
        return {
            redirect: {
                destination,
                permanent,
            },
        }
    }
}

export const adminCheck: getAdminRedirect = async (profilePromise, destination = '/', permanent = false) => {
    const profile = await profilePromise
    if (!profile) {
        return {
            redirect: {
                destination,
                permanent,
            },
        }
    }
    if (profile.type !== 'ADMIN') {
        return {
            redirect: {
                destination,
                permanent,
            },
        }
    }
}

export const bodyLockRemove = (delay = 0) => {
    const body = document.body
    if (body.classList.contains('_lock')) {
        setTimeout(() => {
            body.style.paddingRight = '0px'
            body.classList.remove('_lock', '_lock_overlay')
        }, delay)
    }
}
export const bodyLockAdd = (withOverlay?: boolean) => {
    const body = document.body
    if (!body.classList.contains('_lock')) {
        const paddingRight = window.innerWidth - ((document.querySelector('#__next') as HTMLDivElement)?.offsetWidth ?? 0) + 'px'
        body.style.paddingRight = paddingRight

        body.classList.add('_lock')

        if (withOverlay) {
            body.classList.add('_lock_overlay')
        }
    }
}
export const PrivacyList: IPrivacyItem[] = [
    {
        id: 1,
        title: 'privacy.page.block_1.h2',
        text: 'privacy.page.block_1.text',
    },
    {
        id: 2,
        title: 'privacy.page.block_2.h2',
        text: 'privacy.page.block_2.text',
    },
    {
        id: 3,
        title: 'privacy.page.block_3.h2',
        text: 'privacy.page.block_3.text',
    },
    {
        id: 4,
        title: 'privacy.page.block_4.h2',
        text: 'privacy.page.block_4.text',
    },
    {
        id: 5,
        title: 'privacy.page.block_5.h2',
        text: 'privacy.page.block_5.text',
    },
    {
        id: 6,
        title: 'privacy.page.block_6.h2',
        text: 'privacy.page.block_6.text',
    },
    {
        id: 7,
        title: 'privacy.page.block_7.h2',
        text: 'privacy.page.block_7.text',
    },
    {
        id: 8,
        title: 'privacy.page.block_8.h2',
        text: 'privacy.page.block_8.text',
    },
    {
        id: 9,
        title: 'privacy.page.block_9.h2',
        text: 'privacy.page.block_9.text',
    },
    {
        id: 10,
        title: 'privacy.page.block_10.h2',
        text: 'privacy.page.block_10.text',
    },
    {
        id: 11,
        title: 'privacy.page.block_11.h2',
        text: 'privacy.page.block_11.text',
    },
    {
        id: 12,
        title: 'privacy.page.block_12.h2',
        text: 'privacy.page.block_12.text',
    },
    {
        id: 13,
        title: 'privacy.page.block_13.h2',
        text: 'privacy.page.block_13.text',
    },
    {
        id: 14,
        title: 'privacy.page.block_14.h2',
        text: 'privacy.page.block_14.text',
    },
    {
        id: 15,
        title: 'privacy.page.block_15.h2',
        text: 'privacy.page.block_15.text',
    },
    {
        id: 16,
        title: 'privacy.page.block_16.h2',
        text: 'privacy.page.block_16.text',
    },
    {
        id: 17,
        title: 'privacy.page.block_17.h2',
        text: 'privacy.page.block_17.text',
    },
]

type TermsOfUseItem = {
    id: number
    title: Dictionary
    text: Dictionary
}

export const TermsOfUseList: TermsOfUseItem[] = [
    {
        id: 1,
        title: 'terms.page.block_1.h2',
        text: 'terms.page.block_1.text',
    },
    {
        id: 2,
        title: 'terms.page.block_2.h2',
        text: 'terms.page.block_2.text',
    },
    {
        id: 3,
        title: 'terms.page.block_3.h2',
        text: 'terms.page.block_3.text',
    },
    {
        id: 4,
        title: 'terms.page.block_4.h2',
        text: 'terms.page.block_4.text',
    },
    {
        id: 5,
        title: 'terms.page.block_5.h2',
        text: 'terms.page.block_5.text',
    },
    {
        id: 6,
        title: 'terms.page.block_6.h2',
        text: 'terms.page.block_6.text',
    },
    {
        id: 7,
        title: 'terms.page.block_7.h2',
        text: 'terms.page.block_7.text',
    },
    {
        id: 8,
        title: 'terms.page.block_8.h2',
        text: 'terms.page.block_8.text',
    },
    {
        id: 9,
        title: 'terms.page.block_9.h2',
        text: 'terms.page.block_9.text',
    },
    {
        id: 10,
        title: 'terms.page.block_10.h2',
        text: 'terms.page.block_10.text',
    },
    {
        id: 11,
        title: 'terms.page.block_11.h2',
        text: 'terms.page.block_11.text',
    },
    {
        id: 12,
        title: 'terms.page.block_12.h2',
        text: 'terms.page.block_12.text',
    },
    {
        id: 13,
        title: 'terms.page.block_13.h2',
        text: 'terms.page.block_13.text',
    },
    {
        id: 14,
        title: 'terms.page.block_14.h2',
        text: 'terms.page.block_14.text',
    },
    {
        id: 15,
        title: 'terms.page.block_15.h2',
        text: 'terms.page.block_15.text',
    },
    {
        id: 16,
        title: 'terms.page.block_16.h2',
        text: 'terms.page.block_16.text',
    },
    {
        id: 17,
        title: 'terms.page.block_17.h2',
        text: 'terms.page.block_17.text',
    },
    {
        id: 18,
        title: 'terms.page.block_18.h2',
        text: 'terms.page.block_18.text',
    },
    {
        id: 19,
        title: 'terms.page.block_19.h2',
        text: 'terms.page.block_19.text',
    },
    {
        id: 20,
        title: 'terms.page.block_20.h2',
        text: 'terms.page.block_20.text',
    },
    {
        id: 21,
        title: 'terms.page.block_21.h2',
        text: 'terms.page.block_21.text',
    },
]

export type Equal<S, T> = [S, T] extends [T, S] ? S : never

//@ts-ignore
export const exhaustiveCheck = (param: never) => {}

export const countryList: Array<ICountry> = [
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Andorra', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cape Verde', value: 'CV' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Congo, The Democratic Republic of the', value: 'CD' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: "Cote D'Ivoire", value: 'CI' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
    { label: 'Holy See (Vatican City State)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran, Islamic Republic Of', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: "Korea, Democratic People'S Republic of", value: 'KP' },
    { label: 'Korea, Republic of', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: "Lao People'S Democratic Republic", value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia, Federated States of', value: 'FM' },
    { label: 'Moldova, Republic of', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Netherlands Antilles', value: 'AN' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestinian Territory, Occupied', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Reunion', value: 'RE' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation', value: 'RU' },
    { label: 'RWANDA', value: 'RW' },
    { label: 'Saint Helena', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia and Montenegro', value: 'CS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States', value: 'US' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands, British', value: 'VG' },
    { label: 'Virgin Islands, U.S.', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' },
]

export const isAlphanumeric = (str: string) => /^[a-zA-Z0-9._]+$/.test(str)

export const nFormatter = (num: number, digits: number) => {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
        .slice()
        .reverse()
        .find((item) => num >= item.value)
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

export const burnVariants: Record<RarityTypeRoutes, IBurnVariant> = {
    common: {
        images: {
            desktop: 'banner-burn-desktop-common',
            mobile: 'banner-burn-common',
        },
        price: 15,
    },
    rare: {
        images: {
            desktop: 'banner-burn-desktop-rare',
            mobile: 'banner-burn-rare',
        },
        price: 30,
    },
    super: {
        images: {
            desktop: 'banner-burn-desktop-super',
            mobile: 'banner-burn-superrare',
        },
        price: 60,
    },
    epic: {
        images: {
            desktop: 'banner-burn-desktop-epic',
            mobile: 'banner-burn-epic',
        },
        price: 150,
    },
    legendary: {
        images: {
            desktop: 'banner-burn-desktop-legendary',
            mobile: 'banner-burn-legendary',
        },
        price: 400,
    },
    mythical: {
        images: {
            desktop: 'banner-burn-desktop-mythical',
            mobile: 'banner-burn-mihtycal',
        },
        price: 800,
    },
    one: {
        images: {
            desktop: 'banner-burn-desktop-one',
            mobile: 'banner-burn-one',
        },
        price: 2000,
    },
}

export const getRandomIntInclusive = (min: number, max: number) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1) + min) // The maximum is inclusive and the minimum is inclusive
}

export function getHMAC_SHA1(raw: string | undefined, key: string): string {
    try {
        const hmac = createHmac('SHA1', key)
        hmac.write(raw)
        hmac.end()
        return hmac.read().toString('hex')
    } catch (err) {
        console.error('error getHMAC_SHA1', err)
        return ''
    }
}

export const getToyModelHref = (archiveName: model3d['archiveName'], hash: model3d['hash'], extension: modelExtensionTypes) =>
    `https://chikoroko.b-cdn.net/toys/models/${archiveName}${!!hash ? `/${hash}/` : '/'}scene.${extension}`

export const getToyDraftModelHref = (profileId: number, modelId: string | null, create: boolean = false) =>
    create ? `https://sg.storage.bunnycdn.com/dev-site/users_uploads/${profileId}/toys/models/drafts/3d/${modelId}` : `https://chikoroko.b-cdn.net/users_uploads/${profileId}/toys/models/drafts/3d/${modelId}`

export const generateModelHref = (profileId: number, modelId: string | null, extension: modelExtensionTypes) =>
    `https://sg.storage.bunnycdn.com/dev-site/users_uploads/${profileId}/toys/models/drafts/3d/${modelId}.${extension}`

export function generateUniqueFileName(originalName: string): string {
    const timestamp = new Date().getTime()
    const hash = createHash('md5')
        .update(originalName + timestamp)
        .digest('hex')
    return `${hash}-${originalName}`
}

export function generateUniqueFileNameWithoutOriginalName(originalName: string): string {
    const timestamp = new Date().getTime()
    const hash = createHash('md5')
        .update(originalName + timestamp)
        .digest('hex')
    return hash
}

export const decorateFileName = (title: string, size: number, extension: string, isMobile = false) => {
    // ToDO эти функции описаны в компонентах админки может вынести ?
    const trimmedTitle = title.replace('.original', '').replace('.mobile', '')

    const platformDecorator = isMobile ? '.mobile' : '.original'
    switch (size) {
        case 1:
            return trimmedTitle + platformDecorator + extension
        default:
            return trimmedTitle + platformDecorator + `@${size}x` + extension
    }
}

const extMap = {
    png: '.png',
    jpg: '.jpg',
}

export const getAllCdnFileNames = (pureFileName: string) => {
    let imageNames: string[] = []

    for (const size of [1, 2, 3]) {
        imageNames = [
            ...imageNames,
            decorateFileName(pureFileName, size, '.webp', true),
            decorateFileName(pureFileName, size, extMap['png'], true),
            decorateFileName(pureFileName, size, extMap['jpg'], true),
            decorateFileName(pureFileName, size, '.webp', false),
            decorateFileName(pureFileName, size, extMap['png'], false),
            decorateFileName(pureFileName, size, extMap['jpg'], false),
        ]
    }

    return imageNames
}

export namespace CONSOLE {
    export const RED = '\x1b[31m%s\x1b[0m'
    export const GREEN = '\x1b[32m%s\x1b[0m'
}

export function areSetsEqual(set1: Set<number>, set2: Set<number>) {
    // Проверяем, равен ли размер сетов
    if (set1.size !== set2.size) return false

    // Преобразуем set1 в массив и проверяем, содержит ли set2 все элементы set1
    return Array.from(set1).every((item) => set2.has(item))
}
