import { blockquoteNodeType, headingNodeType, paragraphNodeType, reactComponentNodeType } from '@components/UIKit/TextToolbar/utils/constants'
import { PostView } from '@data/post/PostManagerSpec'
import { JSONContent } from '@tiptap/react'
import { LOCAL_LOADED_IMAGE, LOCAL_LOADED_URL_IMAGE } from '../constants'

export const submitPost = () => {
    document.dispatchEvent(new CustomEvent('post_submit'))
}

export const isLocalImage = (url: string) => url.startsWith(LOCAL_LOADED_IMAGE) || url.startsWith(LOCAL_LOADED_URL_IMAGE)

export type PostEventState = 'default' | 'disabled' | 'loading' | 'error'

export const editPostEvent = (state: PostEventState) => {
    document.dispatchEvent(new CustomEvent('post_edit', { detail: state }))
}

export const getPostCardName = (post: PostView) => (post.type === 'NEWS' ? 'R.Toys' : post.creator?.shortid)

export const isPostPersonal = (uid: undefined | number, authorid: undefined | number) => !!(uid && authorid && uid === authorid)

// TODO: Функцию нужно упрастить. Теперь не нужно добавлять три точки, достаточно просто сократить текст, т.е. забрать все текстовые ноды
// и выстовить максимальный лимит для маленьких карточке (должно быть совместимо с SEO)

export const truncateTipTapText = (content: JSONContent[], CHAR_LIMIT: number = 50): JSONContent[] => {
    let totalChars = 0
    let limitReached = false
    let contentTruncated = false

    const processNode = (node: JSONContent): JSONContent | null => {
        if (limitReached) {
            contentTruncated = true
            return null
        }

        if (node.type === reactComponentNodeType || node.type === blockquoteNodeType || node.type === paragraphNodeType || node.type === headingNodeType) {
            const newNode: JSONContent = { ...node }
            if (node.content) {
                const newContent: JSONContent[] = []
                for (const childNode of node.content) {
                    if (limitReached) {
                        contentTruncated = true
                        break
                    }
                    const processedChild = processNode(childNode)
                    if (processedChild) {
                        newContent.push(processedChild)
                    }
                }
                newNode.content = newContent.length > 0 ? newContent : undefined
            }
            return newNode.content ? newNode : null
        } else if (node.type === 'text') {
            if (totalChars >= CHAR_LIMIT) {
                limitReached = true
                contentTruncated = true
                return null
            }

            const text = node.text || ''
            const words = text.match(/(\s*\S+\s*)/g) || []

            let newText = ''
            let wordAdded = false

            for (const word of words) {
                const wordLength = word.length
                if (totalChars + wordLength > CHAR_LIMIT) {
                    if (wordAdded) {
                        newText = newText.trimEnd() + '...'
                    } else {
                        newText = '...'
                    }
                    totalChars += 3 // Count the '...'
                    limitReached = true
                    contentTruncated = true
                    break
                } else {
                    newText += word
                    totalChars += wordLength
                    wordAdded = true
                }
            }

            if (newText) {
                return {
                    ...node,
                    text: newText,
                }
            } else {
                return null
            }
        } else if (node.type === 'image') {
            return null
        } else if (node.content) {
            const newNode: JSONContent = { ...node }
            const newContent: JSONContent[] = []
            for (const childNode of node.content) {
                if (limitReached) {
                    contentTruncated = true
                    break
                }
                const processedChild = processNode(childNode)
                if (processedChild) {
                    newContent.push(processedChild)
                }
            }
            newNode.content = newContent.length > 0 ? newContent : undefined
            return newNode.content ? newNode : null
        } else {
            return null
        }
    }

    const result: JSONContent[] = []

    for (const node of content) {
        if (limitReached) {
            contentTruncated = true
            break
        }
        const processedNode = processNode(node)
        if (processedNode) {
            result.push(processedNode)
        }
    }

    if (contentTruncated) {
        const addEllipsisToLastTextNode = (nodes: JSONContent[]) => {
            for (let i = nodes.length - 1; i >= 0; i--) {
                const node = nodes[i]
                if (node.type === 'text') {
                    if (!node.text?.endsWith('...')) {
                        node.text = node.text?.trimEnd() + '...'
                    }
                    return true
                } else if (node.content) {
                    const found = addEllipsisToLastTextNode(node.content)
                    if (found) return true
                }
            }
            return false
        }

        addEllipsisToLastTextNode(result)
    }

    return result
}

export class PreviewService {
    static getPostPreviewImageNode(content: JSONContent[]) {
        return content.find((node) => node.type === 'image')
    }

    static getHeadingText(content: JSONContent[]) {
        const headingNode = content.find((el) => el?.type === headingNodeType)

        return (
            headingNode?.content?.reduce((acc, el) => {
                if (el.type === 'text') {
                    return acc + (el.text || '')
                }
                return acc
            }, '') ?? ''
        )
    }

    static getHeadlessContent(content: JSONContent[]) {
        return content.filter((el) => el?.type !== headingNodeType)
    }

    static getPreviewContent(content: JSONContent[]) {
        const headlessContent = PreviewService.getHeadlessContent(content)
        const imageNode = PreviewService.getPostPreviewImageNode(content)
        const headingText = PreviewService.getHeadingText(content)
        return { headlessContent, imageNode, headingText }
    }
}
