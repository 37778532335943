import { minGoldPriceForSelling, minRubyPriceForSelling } from '@specs/constants'
import { achivement, feed, feed_event, feed_source, feedTrigger, profile, toy, ValueTypes } from '@specs/specs'
import { JsonValue } from '@specs/types'
import { Toy } from '@specs/views'
import { z } from 'zod'
import { dimensions, sizesModel } from './cdn'

export namespace FeedCards {
    export type post = Pick<
        feed & {
            feedTrigger: feedTrigger[]
        },
        'id' | 'event' | 'source' | 'title' | 'description' | 'imageid' | 'uri' | 'created' | 'feedTrigger'
    >
    type profile = post & {
        shortId: string
    }
    type purchase = post & {
        purchaseId: number
    }
    type artist = post & {
        artistId: number
    }

    type branded<T extends post, brand extends T['event']> = T & {
        event: brand
    }

    export type earn = branded<purchase, 'earn'>
    export type bought = branded<purchase, 'bought'>
    export type sold = branded<purchase, 'sold'>
    export type follower = branded<profile, 'follower'>
    export type burn = branded<
        profile & {
            toyId: number
        },
        'burn'
    >
    export type completedAchivement = branded<
        post & {
            achivement: achivement
        },
        'completedAchivement'
    >

    export type collectionCard = post & {
        collecitonId: number
    }
    export type collection = branded<collectionCard, 'collection'>

    export type completedSet = branded<collectionCard & profile, 'completedSet'>
    export type collectedBonusToy = branded<
        collectionCard &
            profile & {
                toyId: number
            },
        'collectedBonusToy'
    >

    export type artistDrop = branded<
        artist & {
            toyId: number
        },
        'artistToy'
    >
    export type artistSet = branded<artist & collectionCard, 'artistSet'>
    export type topSell = branded<artist & purchase, 'artistTopSell'>

    export type shareReward = branded<purchase & profile, 'shareReward'>
    export type shareOwnReward = branded<purchase & profile, 'shareOwnReward'>
    export type artistRegistration = branded<profile, 'artistRegistration'>
    export type artistToyCreate = branded<profile, 'artistToyCreate'>
    export type artistSetCreate = branded<profile, 'artistSetCreate'>
    export type artistFollowing = branded<artist, 'artistFollowing'>

    export type referralBonus = branded<
        post & {
            body: JsonValue
        },
        'referralBonus'
    >
    export type referralLevel = branded<
        post & {
            body: JsonValue
        },
        'referralLevel'
    >

    namespace validators {
        // We had Issue with nativeEnum from prisma in Browser Runtime
        // so we did real object copy, linked by type system
        const expectedEvents: Array<feed_event> = [
            'post',
            'earn',
            'bought',
            'sold',
            'follower',
            'burn',
            'collection',
            'completedAchivement',
            'completedSet',
            'collectedBonusToy',
            'artistToy',
            'artistSet',
            'artistTopSell',
            'shareReward',
            'referralBonus',
            'referralLevel',
            'shareOwnReward',
            'artistRegistration',
            'artistSetCreate',
            'artistToyCreate',
        ]
        const expectedSources: Array<feed_source> = ['ChikoRoko', 'ArtToys', 'Artist', 'Profile']

        export const post = z.object({
            id: z.number(),
            event: z.enum(expectedEvents as [string]),
            source: z.enum(expectedSources as [string]),
            title: z.string().optional(),
            description: z.string().optional(),
            body: z.any(),
            imageid: z.string().nullable(),
            uri: z.string().nullable(),
            created: z.any(),
            actorId: z.any(),
            shortId: z.any(),
            toyId: z.any(),
            artistId: z.any(),
            purchaseId: z.any(),
            collectionId: z.any(),
            achivement: z.any(),
        })

        const profile = post.extend({ shortId: z.string().nullable() })
        const purchase = post.extend({ purchaseId: z.number().gt(0) })
        const artist = post.extend({ artistId: z.number().gt(0) })

        export const earn = purchase.extend({ event: z.literal('earn') })
        export const bought = purchase.extend({ event: z.literal('bought') })
        export const sold = purchase.extend({ event: z.literal('sold') })
        export const follower = profile.extend({ event: z.literal('follower') })

        export const burn = profile.extend({ toyId: z.number().gt(0).nullable() }).extend({ event: z.literal('burn') })
        export const completedAchivement = profile.extend({ achivement: z.nativeEnum(achivement) }).extend({ event: z.literal('completedAchivement') })

        export const collection = post.extend({ collecitonid: z.number().gt(0).nullable() }).extend({ event: z.literal('collection') })
        export const completedSet = collection.merge(profile).extend({ event: z.literal('completedSet') })
        export const collectedBonusToy = collection.merge(purchase).extend({ event: z.literal('collectedBonusToy') })
        export const artistDrop = artist.extend({ toyid: z.number().gt(0) }).extend({ event: z.literal('artistToy') })
        export const artistSet = artist.merge(collection).extend({ event: z.literal('artistSet') })
        export const topSell = post.merge(purchase).extend({ event: z.literal('artistTopSell') })
        export const shareReward = post.merge(profile).extend({ event: z.literal('shareReward') })
        export const shareOwnReward = post.merge(profile).extend({ event: z.literal('shareOwnReward') })
        export const referralBonus = post.merge(profile).extend({ event: z.literal('referralBonus') })
        export const referralLevel = post.merge(profile).extend({ event: z.literal('referralLevel') })
        export const artistRegistration = post.extend({ event: z.literal('artistRegistration') })
        export const artistToyCreate = post.extend({ event: z.literal('artistToyCreate') })
        export const artistSetCreate = post.extend({ event: z.literal('artistSetCreate') })
        export const artistFollowing = post.extend({ event: z.literal('artistFollowing') })
    }

    type IfAny<T, Y, N> = 0 extends 1 & T ? Y : N
    const typeguard =
        <S>(Schema: z.Schema) =>
        (T: unknown): T is IfAny<S, never, S> =>
            Schema.safeParse(T)['success']

    // // FOR DEBUG PURPOSE:
    // const typeguard = <S>(Schema: z.Schema) => (T: unknown): T is IfAny<S, never, S> => {
    //     try{
    //         Schema.parse(T)
    //     }
    //     catch(e)
    //     {
    //         //@ts-ignore
    //         console.error({shape: Schema.shape , T })
    //         console.error(e)
    //     }
    //     return Schema.safeParse(T)['success']
    // }

    export const is = {
        post: typeguard<post>(validators.post),
        earn: typeguard<earn>(validators.earn),
        bought: typeguard<bought>(validators.bought),
        sold: typeguard<sold>(validators.sold),
        follower: typeguard<follower>(validators.follower),
        burn: typeguard<burn>(validators.burn),
        collection: typeguard<collection>(validators.collection),
        completedAchivement: typeguard<completedAchivement>(validators.completedAchivement),
        completedSet: typeguard<completedSet>(validators.completedSet),
        collectedBonusToy: typeguard<collectedBonusToy>(validators.collectedBonusToy),
        artistToy: typeguard<artistDrop>(validators.artistDrop),
        artistSet: typeguard<artistSet>(validators.artistSet),
        artistTopSell: typeguard<topSell>(validators.topSell),
        shareReward: typeguard<shareReward>(validators.shareReward),
        shareOwnReward: typeguard<shareReward>(validators.shareOwnReward),
        referralBonus: typeguard<referralBonus>(validators.referralBonus),
        referralLevel: typeguard<referralLevel>(validators.referralLevel),
        artistRegistration: typeguard<artistRegistration>(validators.artistRegistration),
        artistToyCreate: typeguard<artistToyCreate>(validators.artistToyCreate),
        artistSetCreate: typeguard<artistSetCreate>(validators.artistSetCreate),
        artistFollowing: typeguard<artistFollowing>(validators.artistFollowing),
    } as const satisfies Record<feed_event, unknown>

    // example:
    // if (is.post(Test)) {
    //     console.info(Test.id);
    // }
}

export interface GAIProduct {
    item_name?: string // Name or ID is required.
    item_id?: number
    item_category: 'Gold' | 'Ruby' | 'Sub' | 'Toy'
    item_variant?: 'payment_gold' | 'payment_ruby' | undefined
    quantity: number
    price?: number | null
    item_brand: 'ArToys' | string
}

type ga4Events = 'select_item' | 'view_item_list' | 'view_item' | 'add_to_cart' | 'begin_checkout' | 'remove_from_cart' | 'add_to_cart_currency' | 'remove_from_cart_currency'

export const dataLayerPush = (event: ga4Events, productObj: GAIProduct[]) => {
    // @ts-ignore
    if (process.env.NEXT_PUBLIC_ENV != 'prod' || !window?.dataLayer) return

    // @ts-ignore
    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    // @ts-ignore
    window.dataLayer.push({
        event: event,
        ecommerce: {
            items: productObj,
        },
    })
}

interface gtag {
    action:
        | 'adding_to_collection'
        | `go_to_${'telegram' | 'instagram' | 'X' | 'tiktok' | 'discord' | 'youtube' | 'vk' | 'facebook' | 'medium' | 'twitch' | 'shopify' | 'chikoroko_art'}`
        | 'search'
        | 'click_complete_tasks'
        | 'add_to_basket'
        | 'go_to_basket'
        | 'click_ar_desktop'
    category: 'action' | 'commerce' | 'social' | 'commercial' | ''
}

export const gtagEventTrigger = (options: gtag) => {
    // @ts-ignore
    if (process.env.NEXT_PUBLIC_ENV != 'prod' || !window?.gtag) return
    // @ts-ignore
    window.gtag('event', options.action, {
        event_category: options.category,
        event_label: 'GA4',
    })
}

export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>

export const createSecretQrLink = (name: toy['slug'], code: string) => `${process.env.NEXTAUTH_URL ?? window.location.origin + '/'}api/qr?secret=${code}&name=${name}`

export const sendToMMMessage = async (message: string) => {
    try {
        const data = {
            text: message,
        }
        await fetch('https://mm.chikoroko.wiki/hooks/n7ifxm4pwt88m89iogr6884oxa', {
            method: 'POST',
            body: JSON.stringify(data),
        })
    } catch (error) {
        console.error('Error sending message:', error)
    }
}

export const getMinPriceForSelling = (currency: Exclude<ValueTypes, 'MONEY' | 'TOY'>) => (currency === 'GOLD' ? minGoldPriceForSelling : minRubyPriceForSelling)

export const getCorrectPrice = (price: number, currency: ValueTypes) => (currency === 'RUBY' ? price * 100 : price)

export type triggerName = 'Signup' | 'Artist Form' | 'Buy Gold' | 'Buy Rubies' | 'Purchase Confirmation' | 'Subscription Confirmation'

export async function handleSendTriggerClient(trigger: triggerName) {
    const url = 'https://artoys.app/api/ga4/adForm?trigger'

    try {
        await fetch(`${url}=${trigger}`, { method: 'POST' })
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
    }
}

export const shuffle = (array: Array<any>) => {
    let currentIndex = array.length,
        randomIndex

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }

    return array
}

export const SizesSchema = z.array(
    z.object({
        width: z.number().gt(0),
        height: z.number().gt(0),
        isMobile: z.boolean(),
        remotePath: z.string(),
    }),
)

/** returns object like { a:1 , b: 2} into query string like a=1,b=2 */
export const formatParamsForCloud = (keys) =>
    Object.entries(keys)
        .map(([k, v]) => `${k}=${v}`)
        .join(',')

export const getCloutUri = (id, params) => `https://imagedelivery.net/${process.env.NEXT_PUBLIC_CLOUD_ACCOUNT_HASH}/${id}/${formatParamsForCloud(params)}`

export const configIsValid = (conf: sizesModel | dimensions[]): conf is sizesModel => SizesSchema.safeParse(conf).success

export const haveAvailableToys = (toy: Pick<Toy.Single, 'available' | 'reserved'> | null | undefined) => (!toy ? false : toy.available > 0)

export const getArtistWithCollaboratorsId = (artistId?: profile['shortid'] | null, collaborators?: Array<profile['shortid'] | undefined>) => {
    const AMOUNT_OF_COLLABORATORS = 5
    return artistId ? [artistId, ...(collaborators ?? [])].slice(0, AMOUNT_OF_COLLABORATORS) : ([] as string[])
}

// Вход: 9999444423
// Выход: 9 999 444 423
export const formatWithSpaces = (num: number | string): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatRubyAmount = (num: number) => num / 100

export const copyHandler = async (text: string) => await navigator.clipboard.writeText(text)
