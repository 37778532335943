/**
 * @deprecated
 * use trpc (api/v2) instead
 * */
export const ApiRoutes = {
    SEARCH_CREATE_INDEX_TOYS: '/api/admin/search/toys/add',
    SEARCH_DELETE_INDEX_TOYS: '/api/admin/search/toys/delete',
    SEARCH_CREATE_INDEX_COLLECTIONS: '/api/admin/search/collections/add',
    SEARCH_DELETE_INDEX_COLLECTIONS: '/api/admin/search/collections/delete',
    PROFILE_REFERRAL: '/api/v1/profile/history/refferal',

    KYC_VERIFIED_CHECK: '/api/kyc/verified-check',
    KYC_CREATE_ACCESS_KEY: '/api/kyc/create-access-key',
}
