import cn from 'classnames';
import Image, { ImageProps } from 'next/image';
import { FC, useState, useCallback, useMemo } from 'react';
type ExtensionType = 'jpg' | 'webp' | 'png' | 'avif';
type PlatformDecorator = '.mobile' | '.original';
interface OptimizedImageProps extends Omit<ImageProps, 'src' | 'priority'> {
  path: string;
  cdnId: string;
  alt: string;
  className?: string;
  width?: number;
  height?: number;
  priority?: boolean;
  quality?: number;
  sizes?: string;
  fill?: boolean;
  resolutions?: 1 | 2 | 3;
  availableExtensions?: ExtensionType[];
  platformDecorator?: PlatformDecorator;
  boxClassName?: string;
  onError?: () => void;
  onLoad?: () => void;
}
const CDN_URL = 'https://chikoroko.b-cdn.net';
const DEFAULT_RESOLUTIONS = 3;
const DEFAULT_EXTENSIONS: ExtensionType[] = ['webp', 'png'];
interface GenerateImageUrlProps {
  cdnId: string;
  path: string;
  extension: ExtensionType;
  platformDecorator: PlatformDecorator;
  resolutions: number;
}
const generateImageUrl = ({
  cdnId,
  path,
  extension = 'webp',
  platformDecorator = '.original',
  resolutions = DEFAULT_RESOLUTIONS
}: GenerateImageUrlProps): string => {
  const idDecorator = resolutions > 1 ? `@${resolutions}x` : '';
  return `${CDN_URL}${path}/${cdnId}${platformDecorator}${idDecorator}.${extension}`;
};
export const OptimizedImage: FC<OptimizedImageProps> = ({
  path,
  cdnId,
  width,
  height,
  quality = 75,
  sizes = '100vw',
  fill = false,
  resolutions = DEFAULT_RESOLUTIONS,
  availableExtensions = DEFAULT_EXTENSIONS,
  platformDecorator = '.original',
  boxClassName,
  onError,
  onLoad,
  ...props
}) => {
  const imageUrl = useMemo(() => {
    return generateImageUrl({
      cdnId,
      path,
      extension: availableExtensions[0],
      platformDecorator,
      resolutions
    });
  }, [cdnId, path, resolutions, availableExtensions, platformDecorator]);

  // console.log('imageUrl:', imageUrl)
  const [hasError, setHasError] = useState(false);
  const handleError = useCallback(() => {
    if (!hasError) {
      setHasError(true);
      onError?.();
    }
  }, [hasError, onError]);
  const handleLoad = useCallback(() => {
    onLoad?.();
  }, [onLoad]);
  return <div className={cn('_relative', boxClassName)} data-sentry-component="OptimizedImage" data-sentry-source-file="OptimizedImage.tsx">
			<Image src={imageUrl} onError={handleError} onLoad={handleLoad} className={cn('_img', '_img_loaded', props.className)} decoding='async' quality={quality} sizes={sizes} priority loading={'eager'} placeholder="blur" blurDataURL={`data:image/svg+xml;base64,${btoa(`<svg width="${width || 100}" height="${height || 100}" 
						xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
						<rect width="100%" height="100%" fill="#f5f5f5"/>
						</svg>`)}`} style={{}} {...fill && (!width || !height) ? {
      fill: true
    } : {
      width: width || 100,
      height: height || 100
    }} {...props} data-sentry-element="Image" data-sentry-source-file="OptimizedImage.tsx" />
		</div>;
};